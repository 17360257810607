/**
 * NAIC InsData Customer Service API
 * NAIC InsData Customer Service API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {Observable} from 'rxjs';

import {Authority} from '../model/common/authority';
import {BannerInput} from '../model/customer/bannerInput';
import {ContractUpsertInput} from '../model/customer/contractUpsertInput';
import {EndUserAgreement} from '../model/customer/endUserAgreement';
import {User} from '../model/customer/user';
import {UserDetail} from '../model/customer/userDetail';
import {UserInput} from '../model/customer/userInput';
import {UserRegistration} from '../model/customer/userRegistration';
import {UserSearchResult} from '../model/customer/userSearchResult';

import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class CustomerService {

  protected basePath = 'https://localhost:8443';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Fetch available authorities (admin only)
   * Fetch available authorities (admin only)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchAuthorities(observe?: 'body', reportProgress?: boolean): Observable<Array<Authority>>;
  public fetchAuthorities(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Authority>>>;
  public fetchAuthorities(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Authority>>>;
  public fetchAuthorities(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Authority>>(`${this.basePath}/customer/authorities`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get the end user agreement text based on authority of user.
   * Get the end user agreement text based on authority of user.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchEndUserAgreement(observe?: 'body', reportProgress?: boolean): Observable<EndUserAgreement>;
  public fetchEndUserAgreement(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EndUserAgreement>>;
  public fetchEndUserAgreement(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EndUserAgreement>>;
  public fetchEndUserAgreement(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<EndUserAgreement>(`${this.basePath}/customer/userAgreement`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user by user Id.  (for client can only lookup self)
   * Get user by user Id.  (for client can only lookup self)
   * @param userId The Id of the user that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchUserById(userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public fetchUserById(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public fetchUserById(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public fetchUserById(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling fetchUserById.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserDetail>(`${this.basePath}/customer/usersById/${encodeURIComponent(String(userId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user by user name.  (for client can only lookup self)
   * Get user by user name.  (for client can only lookup self)
   * @param userName The name of the user that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchUserByName(userName: string, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public fetchUserByName(userName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public fetchUserByName(userName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public fetchUserByName(userName: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userName === null || userName === undefined) {
      throw new Error('Required parameter userName was null or undefined when calling fetchUserByName.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserDetail>(`${this.basePath}/customer/users/${encodeURIComponent(String(userName))}/`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get user by preferred user Id.  (for client can only lookup self)
   * Get user by preferred user Id.  (for client can only lookup self)
   * @param preferredUserId The preferred Id of the user that needs to be fetched.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchUserByPreferredUserId(preferredUserId: string, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public fetchUserByPreferredUserId(preferredUserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public fetchUserByPreferredUserId(preferredUserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public fetchUserByPreferredUserId(preferredUserId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (preferredUserId === null || preferredUserId === undefined) {
      throw new Error('Required parameter preferredUserId was null or undefined when calling fetchUserByPreferredUserId.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserDetail>(`${this.basePath}/customer/userByPreferredUserId/${encodeURIComponent(String(preferredUserId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get users by id list.
   * Get users by id list.
   * @param ids List of userIds.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public fetchUsersByIds(ids: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
  public fetchUsersByIds(ids: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
  public fetchUsersByIds(ids: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
  public fetchUsersByIds(ids: Array<string>, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (ids === null || ids === undefined) {
      throw new Error('Required parameter ids was null or undefined when calling fetchUsersByIds.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<User>>(`${this.basePath}/customer/usersByIds`,
      ids,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * NOTE: Please don't replace this method with default method from generated code. This method doesn't require any authentication.
   *
   * Get banner
   * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBanner(observe?: 'body', reportProgress?: boolean): Observable<BannerInput>;
  public getBanner(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BannerInput>>;
  public getBanner(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BannerInput>>;
  public getBanner(): Observable<any> {
    return this.httpClient.get<BannerInput>(`${this.basePath}/customer/banner` );
  }

  /**
   * Register user with system.  (client only)
   * This can only be done by the logged in user.  (client only)
   * @param body Registration user object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerUser(body: UserRegistration, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public registerUser(body: UserRegistration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public registerUser(body: UserRegistration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public registerUser(body: UserRegistration, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling registerUser.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<UserDetail>(`${this.basePath}/customer/users`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search for users. (admin only)
   * Search for users. (admin only)
   * @param firstName First Name search (partial)
   * @param lastName Last Name search (partial)
   * @param companyName Company Name search (partial)
   * @param email Email search (partial)
   * @param userName User Name search (partial)
   * @param startCreationDate Start creation date range (exact)
   * @param endCreationDate End creation date range (exact)
   * @param start start item
   * @param limit item limit
   * @param sort Column to sort on
   * @param sortOrder Sort Order
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchForUsers(firstName?: string, lastName?: string, companyName?: string, email?: string, userName?: string, startCreationDate?: Date, endCreationDate?: Date, start?: number, limit?: number, sort?: 'firstName' | 'lastName' | 'userName' | 'companyName' | 'email', sortOrder?: 'ASC' | 'DESC', observe?: 'body', reportProgress?: boolean): Observable<UserSearchResult>;
  public searchForUsers(firstName?: string, lastName?: string, companyName?: string, email?: string, userName?: string, startCreationDate?: Date, endCreationDate?: Date, start?: number, limit?: number, sort?: 'firstName' | 'lastName' | 'userName' | 'companyName' | 'email', sortOrder?: 'ASC' | 'DESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSearchResult>>;
  public searchForUsers(firstName?: string, lastName?: string, companyName?: string, email?: string, userName?: string, startCreationDate?: Date, endCreationDate?: Date, start?: number, limit?: number, sort?: 'firstName' | 'lastName' | 'userName' | 'companyName' | 'email', sortOrder?: 'ASC' | 'DESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSearchResult>>;
  public searchForUsers(firstName?: string, lastName?: string, companyName?: string, email?: string, userName?: string, startCreationDate?: Date, endCreationDate?: Date, start?: number, limit?: number, sort?: 'firstName' | 'lastName' | 'userName' | 'companyName' | 'email', sortOrder?: 'ASC' | 'DESC', observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (firstName !== undefined && firstName !== null) {
      queryParameters = queryParameters.set('firstName', <any>firstName);
    }
    if (lastName !== undefined && lastName !== null) {
      queryParameters = queryParameters.set('lastName', <any>lastName);
    }
    if (companyName !== undefined && companyName !== null) {
      queryParameters = queryParameters.set('companyName', <any>companyName);
    }
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }
    if (userName !== undefined && userName !== null) {
      queryParameters = queryParameters.set('userName', <any>userName);
    }
    if (startCreationDate !== undefined && startCreationDate !== null) {
      queryParameters = queryParameters.set('startCreationDate', <any>startCreationDate.toISOString());
    }
    if (endCreationDate !== undefined && endCreationDate !== null) {
      queryParameters = queryParameters.set('endCreationDate', <any>endCreationDate.toISOString());
    }
    if (start !== undefined && start !== null) {
      queryParameters = queryParameters.set('start', <any>start);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortOrder !== undefined && sortOrder !== null) {
      queryParameters = queryParameters.set('sortOrder', <any>sortOrder);
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserSearchResult>(`${this.basePath}/customer/userSearch`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update User
   * Update user
   * @param body User update contents
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateUser(body: UserInput, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public updateUser(body: UserInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public updateUser(body: UserInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public updateUser(body: UserInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateUser.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<UserDetail>(`${this.basePath}/customer/users`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Upsert a User&#39;s Contract(s)
   * Upsert a User&#39;s Contract(s)
   * @param userId The Id of the user that needs to have their contract(s) created/updated.
   * @param body Contract upsert contents.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public upsertUserContracts(userId: string, body: ContractUpsertInput, observe?: 'body', reportProgress?: boolean): Observable<UserDetail>;
  public upsertUserContracts(userId: string, body: ContractUpsertInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDetail>>;
  public upsertUserContracts(userId: string, body: ContractUpsertInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDetail>>;
  public upsertUserContracts(userId: string, body: ContractUpsertInput, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling upsertUserContracts.');
    }

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling upsertUserContracts.');
    }

    let headers = this.defaultHeaders;

    // authentication (OAuth2) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<UserDetail>(`${this.basePath}/customer/users/${encodeURIComponent(String(userId))}/userContracts`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
